@import '/src/App/Global/Style/_imports.scss';

.profile {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: #22B1A9;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .3s, border .3s linear;
  background-color: #17616F;
  border: 0 solid lightgray;
}

.logoff-anchor {
  padding-top: $zr-spacing-vertical-xs;
}

.profile:focus  .profile-content, .profile:focus-within  .profile-content {
  display: flex;
}

.profile:focus, .profile:focus-within {
  pointer-events: none;
  border-width: 3px;
}

.picture {
  width: 100px;
  height: 100px;
  font-size: 4rem;
  font-family: $ff-light;
  margin: 10px 0;
}

.profile-content {
  display: none;
  width: min(344px, 100vw);
  position: fixed;
  background-color: #F9F9F9;
  top: 100%;
  right: 0;
  box-shadow: 0px 3px 6px #0000004D;
  align-items: center;
  padding: 30px;
  border-top: 1px solid #17616F;
  flex-direction: column;
  gap: 10px;
  pointer-events:all;
  cursor: default;
  @include mobile {
    padding: 0 30px 30px 30px;
  }
}

@include mobile {
  img[alt="meu-negocio"] {
    padding-top: 30px;
  }
  
}


.profile-content h1 {
  color: black;
  font-size: 1.5rem;
  font-family: $ff-light;
}

.store-info {
  width: 100%;
  border-top: thin solid rgba(211, 211, 211, 0.637);
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.store-info a {
  color: blue;
  text-decoration: underline;
  font-size: $zr-font-size-xxxs;
  font-family: $ff-light;
}

.profile-content h2 {
  color: black;
  font-size: $zr-font-size-xxxs;
  padding: 10px 0;
  font-family: $ff-light;
}

.profile-content h3 {
  color: #7C7C8A;
  font-size: $zr-font-size-nano;
}

.profile-content img {
  width: 150px;
  padding-bottom: 20px;
}

.profile-content hr {
  border-top: thin solid rgba(211, 211, 211, 0.637);
  width: 100%;
}