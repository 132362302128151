@import "./_variables.scss";

.Toastify__close-button {
  color: #2f6ea6;
}

.Toastify__progress-bar--success{
  background: #0b7c2f !important;
}

.Toastify__progress-bar--error{
  background: $zr-color-brand-primary-03-darkest !important;
}

.Toastify__progress-bar--info{
  background: #0E55A4 !important;
}

.Toastify__progress-bar--warning{
  background: #FFBC2C !important;
}

.Toastify__toast-body > div:last-child {
  color: $dark-gray1;
  font-family: $ff-bold;
}

:root {
  --toastify-color-info: #e2efff;
  --toastify-color-success: #e6f2ea;
  --toastify-color-warning: #fff2d6;
  --toastify-color-error: #fff0f2;
  --toastify-font-family: $ff-default;
}