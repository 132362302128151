@use '/src/App/Global/Style/_imports.scss' as *;
@import "react-toastify/dist/ReactToastify.min.css";
@import "./_toast.scss";


h1,
h2,
h3,
h4,
h5 {
  font-weight: normal;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  font-family: $ff-default;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &::-webkit-scrollbar {
    width: 13px;
  }

  &::-webkit-scrollbar-track {
    background-color: $zr-color-neutral-light;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #a3a3a3;
    border-radius: 10px;
    border: 3px solid $zr-color-neutral-light;
  }
}

html {
  scroll-padding-top: $header-height;

  // /* Adjust font size */
  // font-size: 100%;
  // -webkit-text-size-adjust: 100%;
  // /* Font varient */
  // font-variant-ligatures: none;
  // -webkit-font-variant-ligatures: none;
  // /* Smoothing */
  // text-rendering: optimizeLegibility;
  // -moz-osx-font-smoothing: grayscale;
  // // font-smoothing: antialiased;
  // -webkit-font-smoothing: antialiased;
  // text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
}

body {
  overflow-x: hidden;
  background-color: $zr-color-neutral-light;

  &:has([data-page-kind="light-layout"]) {
    background-color: #fff;
  }
  // Fix for menu transition
  padding-left: $side-menu-width;
  margin-left: -$side-menu-width;


  &.with-layout {
    margin-top: $header-height;
    margin-left: 0;
    padding-left: 0;

    @media screen and (min-width: $breakpoint-mobile) {
      transition: padding-left $menu-compact-trasition-timing;
      padding-left: $side-menu-width;

      &:has(#side-menu.compact) {
        padding-left: $side-menu-width-compact;
      }
    }
  }

  @include mobile {
    &:has(#back-menu) {
      overflow: hidden;
    }
  }
}


body:has(#app) {
  margin-top: $header-height;
  margin-left: 0;
  padding-left: 0;

  @media screen and (min-width: $breakpoint-mobile) {
    transition: padding-left $menu-compact-trasition-timing;
    padding-left: $side-menu-width;

    &:has(#side-menu.compact) {
      padding-left: $side-menu-width-compact;
    }
  }
}

// Esconder botão na tela de seleção de lojas
body:has(div[class^="StoreSelect_page_style_"]) #iframe-button {
  display: none !important;
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;

  @include non-mobile {
    min-height: calc(100vh - $header-height);
  }
}

#app {
  max-width: 1210px;
  width: 100%;
  margin: 0 auto;
  container: app/inline-size;
  flex-grow: 1;
}

.hidden {
  display: none !important;
}

.loading {
  background: linear-gradient(133deg,
      rgba(211, 211, 211, 1) 27%,
      rgba(245, 245, 245, 1) 54%,
      rgba(211, 211, 211, 1) 78%);

  animation-name: loadingAnimation;
  background-size: 400%;
  animation-duration: 4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes loadingAnimation {
  0% {
    background-position-x: 200%
  }

  100% {
    background-position-x: -200%
  }
}


// Ajustes para centralizar os controles do componente Carousel da lib do host
button[class*="Carousel-module_arrow"],
ul[class*="Carousel-module_controls-dots"] {
  display: flex;
  height: 100%;
}

.carousel .slide img {
  width: auto !important;
}

strong {
  font-family: $ff-bold;
}

details summary::-webkit-details-marker {
  display: none;
}

details > summary {
  list-style: none;
}
