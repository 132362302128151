@import '/src/App/Global/Style/_imports.scss';

.main-nav-item {
  width: 100%;
  overflow: hidden;
  cursor: pointer;

  &:has([data-new-feature="true"]) {
    .main-nav-item-title {
      position: relative;

      &:after {
        content: 'NOVO';
        position: absolute;
        width: 51px;
        height: 24px;
        z-index: 9;
        top: 50%;
        transform: translateY(-50%);
        right: 6px;
        background-color: white;
        font-size: 10px;
        color: $zr-color-brand-primary-02-darkest;
        font-weight: $zr-font-weight-bold;
        border: 1px solid $zr-color-brand-primary-02-darkest;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 35px;
        height: 14px;
        margin-left: 4px;
        background-color: $zr-color-brand-primary-02-medium;
        border-radius: 4px;
        z-index: 9;

        color: #000;
        font-family: $ff-bold;
        font-size: 10px;
        font-weight: 700;
      }
    }
  }
}


:global(#side-menu.compact) {
  .main-nav-item {

    &:has([data-new-feature="true"]) {
      .main-nav-item-title::after {
        content: '';
        background: none;
        position: absolute;
        width: 8px;
        border: none;
        height: 8px;
        border-radius: 50%;
        background-color: $zr-color-brand-primary-02-medium;
        right: 8px;
      }
    }
  }
}

.main-nav-item:hover {
  background-color: $zr-color-neutral-light;

}

.main-nav-item-title {
  height: 56px;
  display: flex;
  align-items: center;
  border-right: 4px solid transparent;
  padding-left: 26px;
}

.main-nav-item-title img {
  width: 20px;
  min-width: 20px;
  pointer-events: none;
}

.apps .main-nav-item-title img {
  width: 18px;
  min-width: 18px;
  pointer-events: none;

}

.main-nav-item-title h1 {
  padding-left: 20px;
  pointer-events: none;

}

:global(#side-menu.compact) .main-nav-item-title h1 {
  display: none;
}

img[alt="Inicio"] {
  filter: brightness(0) saturate(100%) invert(56%) sepia(78%) saturate(355%) hue-rotate(128deg) brightness(91%) contrast(96%);
}

img[alt="Aplicativos"] {
  scale: 0.8;
}

.main-nav-item.selected .main-nav-item-title {
  border-right: 4px solid $light-green2;
  background-color: $zr-color-neutral-light;
}

.item-options {
  width: 100%;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s;


}

.selected .item-options {
  max-height: 600px;

}

:global(#side-menu.compact) .item-options {
  max-height: 0;
}

.sub-menu-item {
  font-size: $zr-font-size-micro;
  font-family: $ff-default;
  width: 100%;
  height: 40px;
  background-color: white;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;

  &:has([data-new-feature="true"]) {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      top: 50%;
      transform: translateY(-50%);
      background-color: $zr-color-brand-primary-02-medium;
      right: 14px;
    }
  }
}

.sub-menu-item a {
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 12px;
  padding-left: 54px;
  width: 100%;
  height: 100%;
  display: block;
  transition: padding 0.2s;
  text-decoration: none;
  color: black;
}

.sub-menu-item:hover {
  background-color: $zr-color-neutral-light;
}
