@font-face {
  font-family: 'UOLText';
  src: url(/Assets/Fonts/UolText/uol-text-regular.woff2) format('woff2'),
      url(/Assets/Fonts/UolText/uol-text-regular.woff) format('woff');
  font-style: normal;
  font-weight: $zr-font-weight-regular;
  font-display: swap;
  unicode-range: U+0020-00FF;
}

@font-face {
  font-family: 'UOLTextLight';
  src: url(/Assets/Fonts/UolText/uol-text-light.woff2) format('woff2'),
    url(/Assets/Fonts/UolText/uol-text-light.woff) format('woff');
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  unicode-range: U+0020-00FF;
}

@font-face {
  font-family: 'UOLTextBold';
  src: url(/Assets/Fonts/UolText/uol-text-bold.woff2) format('woff2'),
    url(/Assets/Fonts/UolText/uol-text-bold.woff) format('woff');
  font-style: normal;
  font-weight: $zr-font-weight-bold;
  font-display: swap;
  unicode-range: U+0020-00FF;
}

@font-face {
  font-family: 'UOLTextItalic';
  src: url(/Assets/Fonts/UolText/uol-text-italic.woff) format('woff');
  font-style: italic;
  font-weight: $zr-font-weight-regular;
  font-display: swap;
  unicode-range: U+0020-00FF;
}

@font-face {
  font-family: UOLDisplay;
  src: url(/Assets/Fonts/UolDisplay/UOLdisplay_regular.woff) format(woff);
}

@font-face {
  font-family: UOLDisplay;
  src: url(/Assets/Fonts/UolDisplay/UOLdisplay_regular.woff) format(woff);
  font-weight: normal;
}

@font-face {
  font-family: UOLDisplay;
  src: url(/Assets/Fonts/UolDisplay/UOLdisplay_bold.woff) format(woff);
  font-weight: bold;
}

@font-face {
  font-family: UOLDisplay;
  src: url(/Assets/Fonts/UolDisplay/UOLdisplay_light.woff) format(woff);
  font-weight: light;
}