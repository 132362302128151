@mixin dynamic-viewheight($height) {
  height: #{$height}vh;
  height: --webkit-fill-available;
  height: #{$height}dvh;
}

@mixin mobile($properties...) {
  @media screen and (max-width: $breakpoint-mobile) {
    @content;
  }
}

@mixin non-mobile($properties...) {
  @media screen and (min-width: $breakpoint-mobile) {
    @content;
  }
}

@mixin tablet-and-smaller($properties...) {
  @media screen and (max-width: $breakpoint-tablet) {
    @content;
  }
}

// ignore errors
@mixin CQ($size: $breakpoint-mobile, $properties...) {

  @supports(container: abc) {
    @container (max-width: #{$size}) {
      @content;
    }
  }

  @supports(not(container: abc)) {
    @media screen and (max-width: #{$size}) {
      @content;
    }
  }
}

@mixin reset-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin btn-loja {
  background-color: $dark-green1 !important;

  &:hover:not(:disabled) {
    background-color: #5f929c !important;
  }
}

@mixin btn-loja-loading {
  @include btn-loja;

  &:disabled {
    pointer-events: initial;
    cursor: wait !important;
    opacity: 1;
  }
}

@mixin checkbox {
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  border: 1px solid $zr-color-actions-primary-medium;
  background-color: #fff;
  appearance: none;
  outline: none;
  cursor: pointer;

  &:checked {
    // background: $zr-color-actions-primary-medium url(/Assets/Images/SVG/checkmark.svg) no-repeat center center;
    background: $zr-color-actions-primary-medium url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20d%3D%22M34.1%2C57.448l-9.385%2C8.76L20%2C62.013%22%20transform%3D%22translate(-15.05%20-49.087)%22%20fill%3D%22none%22%20stroke%3D%22%23ececec%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20stroke-width%3D%223%22%2F%3E%3C%2Fsvg%3E") no-repeat center center;
    background-size: contain;
  }

  // &:checked
}

@mixin simpleInput {
  :global(div[data-testid="simpleinput_component"]) {
    width: 100%;
    padding: $zr-spacing-horizontal-xxs $zr-spacing-horizontal-xxs 0;
    border: 1px solid $zr-color-neutral-medium-04;
    border-radius: 2px;
  }

  :global(.display-prefix) {
    margin-right: $zr-spacing-horizontal-micro;
  }

  :global(div.with-alert[data-testid="simpleinput_component"]) {
    border-color: $zr-color-brand-primary-03-darkest;
  }

  :global(div[role="alertdialog"]) {
    padding-top: $zr-spacing-vertical-micro;
    padding-left: $zr-spacing-horizontal-micro;
    background-color: transparent;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-background-clip: text;
    -webkit-text-fill-color: $zr-color-neutral-dark;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px transparent;
  }
}