@import '/src/App/Global/Style/_imports.scss';

.uol-products {
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  max-width: 36px;
}

.uol-products li {
  background-color: white;
}

.uol-products img {
  width: 35px;
}

.uol-products:focus  .uol-products-content, .uol-products:focus-within  .uol-products-content {
  display: flex;
}

.uol-products-content p {
  color: #707070;
  text-align: center;
  font-size: 10px;
}

.uol-products:focus, .uol-products:focus-within {
  pointer-events: none;
}

.uol-products-content {
  cursor: default;
  display: none;
  width: min(344px, 100vw);
  position: fixed;
  background-color: #F9F9F9;
  border-top: 1px solid #17616F;
  top: 100%;
  right: 0;
  box-shadow: 0px 3px 6px #0000004D;
  align-items: center;
  padding: 30px;
  flex-direction: column;
  gap: 20px;
  pointer-events:all;
  text-align: start;
  @include mobile {
    padding: 0 30px 30px 30px;
  }
}

.uol-products-content h1 {
  color: #202020;
  font-weight: bold;
  font-size: $zr-font-size-xxs;
}

.product-card-large {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right bottom;
}

.product-card-large:first-of-type {
  background-image: url(/Assets/Images/PNG/more-products-img1.png);
  background-size: 85px 85px;
}

.product-card-large:nth-of-type(2) {
  background-image: url(/Assets/Images/PNG/more-products-img2.png);
  background-size: 120px 90px;
}

.products-grid {
  width: 282px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 10px;
  row-gap: 20px;
}

.product-card {
  aspect-ratio: 1 /1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: white;
  color: #4d4d57;
  gap: 10px;
  h2 {
    font-size: 80%;
  }
}

.product-card:hover {
  background-color: #d4d4d4;
}


.product-card img {
  width: 40%;
}

.product-card-large {
  color: black;
  background-color: white;
  border: 1px solid #f0f0f0;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 10px;
  gap: 5px;
  grid-column-start: 1;
  grid-column-end: -1;
  height: 111px;
}

.product-card-large h1 {
  font-size: $zr-font-size-xxxs;
  font-weight: normal;
}

.product-card-large h2 {
  font-size: .6rem;
  color: #8b8b8b;
}

.product-card-large:nth-of-type(2) h2 {
  max-width: 170px;
}

.product-card-large:nth-of-type(3) > h2 {
  max-width: 200px;
}

.product-card-large a {
  color: #0070AB;
  margin-top: 5px;
  font-size: .85rem;
  text-decoration: underline;
}

.social {
  display: flex;
  gap: 20px;
  flex-direction: column;
  width: 100%;
}

.social h1 {
  font-size: $zr-font-size-micro;
  color: #343434;
  font-weight: bold;
  text-align: center;
}

.social-icons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.social-icons > a {
  flex-basis: 1px;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .2s;
  height: 20px;
  overflow:visible;
  filter: brightness(0) saturate(100%) invert(94%) sepia(4%) saturate(9%) hue-rotate(325deg) brightness(87%) contrast(89%);
}

.social-icons > a > img {
  height: 110%;

}

.blog:hover {
  filter: brightness(0) saturate(100%) invert(35%) sepia(17%) saturate(5347%) hue-rotate(173deg) brightness(89%) contrast(105%);
}

.instagram {
  height: 22px;
  transform: scaleX(.65);
}

.instagram:hover {
  filter: none;
}

.facebook:hover {
  filter: brightness(0) saturate(100%) invert(43%) sepia(9%) saturate(5785%) hue-rotate(175deg) brightness(88%) contrast(86%);
}

.youtube img {
  height: 130%;
}

.youtube:hover {
  filter: brightness(0) saturate(100%) invert(11%) sepia(70%) saturate(6689%) hue-rotate(357deg) brightness(106%) contrast(123%);
}

.linkedin:hover {
  filter: brightness(0) saturate(100%) invert(35%) sepia(17%) saturate(5347%) hue-rotate(173deg) brightness(89%) contrast(105%);
}

.tiktok:hover {
  filter: brightness(0) saturate(100%) invert(6%) sepia(4%) saturate(22%) hue-rotate(314deg) brightness(97%) contrast(93%);
}

.pinterest:hover {
  filter: brightness(0) saturate(100%) invert(10%) sepia(68%) saturate(6496%) hue-rotate(341deg) brightness(104%) contrast(121%);
}